import pimg1 from '../images/project/1.jpg'
import pimg2 from '../images/project/2.jpg'
import pimg3 from '../images/project/3.jpg'
import pimg4 from '../images/project/4.jpg'
import pimg5 from '../images/project/2.jpg'
import pimg6 from '../images/project/3.jpg'


import p2img1 from '../images/services/img-1.jpg'
import p2img2 from '../images/services/img-2.jpg'
import p2img3 from '../images/services/img-3.jpg'
import p2img4 from '../images/services/img-4.jpg'

import p3img1 from '../images/project/5.jpg'
import p3img2 from '../images/project/6.jpg'
import p3img3 from '../images/project/7.jpg'
import p3img4 from '../images/project/8.jpg'
import p3img5 from '../images/project/9.jpg'


import pSimg1 from '../images/project-single/1.jpg'
import pSimg2 from '../images/project-single/4.jpg'
import pSimg3 from '../images/project-single/1.jpg'
import pSimg4 from '../images/project-single/4.jpg'
import pSimg5 from '../images/project-single/1.jpg'
import pSimg6 from '../images/project-single/4.jpg'





const Projects = [
    {
        id: '1',
        title: 'Eskatania Times Square',
        title2: 'Commercial',
        title3: 'Architecture',
        subtitle: 'KUALA LUMPUR, MALAYSIA',
        subtitle2: 'Architecture Design',
        slug: 'Jack-Rose',
        pimg1: pimg1,
        pimg2: p2img1,
        pimg3: p3img1,
        pSimg: pSimg1,
        date: 'Feb 2024',
        location: 'Austria',
    },
    {
        id: '2',
        title: 'Deska Eskatania Mall',
        title2: 'Floor Plans',
        title3: 'Architecture',
        subtitle: 'ALBERTA, CANADA',
        subtitle2: 'Architecture Design',
        slug: 'Jack-Rose2',
        pimg1: pimg2,
        pimg2: p2img2,
        pimg3: p3img2,
        pSimg: pSimg2,
        date: '1 Feb 2024',
        location: 'Austria',
    },
    {
        id: '3',
        title: 'Smartes Nasaria of Asia',
        title2: 'Interiors',
        title3: 'Interiors',
        subtitle: 'MANILA, PHILIPPINES',
        subtitle2: 'Interior Design',
        slug: 'Jack-Rose3',
        pimg1: pimg3,
        pimg2: p2img3,
        pimg3: p3img3,
        pSimg: pSimg3,
        date: '1 Feb 2024',
        location: 'Austria',
    },
    {
        id: '4',
        title: 'Katariana Prus Malletaka',
        title2: 'Architectures',
        title3: 'Interior',
        subtitle: 'PENNSYLVANIA USA',
        subtitle2: 'Interior Design',
        slug: 'Jack-Rose4',
        pimg1: pimg4,
        pimg2: p2img4,
        pimg3: p3img4,
        pSimg: pSimg4,
        date: '1 Feb 2024',
        location: 'Austria',
    },
    {
        id: '5',
        title: 'Eskatania Times Square',
        title3: 'Interiors',
        subtitle: 'KUALA LUMPUR, MALAYSIA',
        subtitle2: 'Interior Design',
        slug: 'Jack-Rose5',
        pimg1: pimg5,
        pimg3: p3img5,
        pSimg: pSimg5,
        date: '1 Feb 2024',
        location: 'Austria',
    },
    {
        id: '6',
        title: 'Deska Eskatania Mall',
        subtitle: 'ALBERTA, CANADA',
        slug: 'Jack-Rose6',
        pimg1: pimg6,
        pSimg: pSimg6,
        date: '1 Feb 2024',
        location: 'Austria',
    },



]
export default Projects;






