import simg1 from '../images/services/icon-1.png'
import simg2 from '../images/services/icon-2.png'
import simg3 from '../images/services/icon-3.png'
import simg4 from '../images/services/icon-4.png'

import sSimg1 from '../images/service-single/1.jpg'
import sSimg2 from '../images/service-single/1.jpg'
import sSimg3 from '../images/service-single/1.jpg'
import sSimg4 from '../images/service-single/1.jpg'

import icon1 from '../images/services/icon-1.png'
import icon2 from '../images/services/icon-2.png'
import icon3 from '../images/services/icon-3.png'
import icon4 from '../images/services/icon-4.png'


const Services = [
   {
      Id: '01',
      sImg: simg1,
      sSImg: sSimg1,
      icon: icon1,
      title: 'Floor Plans',
      slug: 'Quran-Memorization',
      description: 'It seem that only fragmen the original remain.',
   },
   {
      Id: '02',
      sImg: simg2,
      sSImg: sSimg2,
      icon: icon2,
      title: 'FURNITURE',
      slug: 'Special-Child-Care',
      description: 'It seem that only fragmen the original remain',
   },
   {
      Id: '03',
      sImg: simg3,
      sSImg: sSimg3,
      icon: icon3,
      title: 'PLANNING',
      slug: 'Mosque-Development',
      description: 'It seem that only fragmen the original remain',
   },
   {
      Id: '04',
      sImg: simg4,
      sSImg: sSimg4,
      icon: icon4,
      title: 'Decor Plan',
      slug: 'Charity-&-Donation',
      description: 'It seem that only fragmen the original remain',
   },
   {
      Id: '05',
      sImg: simg2,
      sSImg: sSimg2,
      icon: icon2,
      title: 'FURNITURE',
      slug: 'Special-Child-Care',
      description: 'It seem that only fragmen the original remain',
   },

]

export default Services;