// images
import blogImg1 from "../images/blog/img-1.jpg";
import blogImg2 from "../images/blog/img-2.jpg";
import blogImg3 from "../images/blog/img-3.jpg";
import blogImg4 from "../images/blog/img-2.jpg";

import blogSingleImg1 from "../images/blog-details/1.jpg";
import blogSingleImg2 from "../images/blog-details/2.jpg";
import blogSingleImg3 from "../images/blog-details/1.jpg";




const blogs = [
    {
        id: '1',
        title: 'Lorem Ipsum is simply dummy text of printing and typesetting.',
        title2: '10 Mistakes To Avoid When Investing In Real Estate.',
        subtitle: 'ARCHITECTURE / BY: Dos',
        slug: 'How-To-Teach-Kids-Ramadan-Isn’t-About-Food1',
        screens: blogImg1,
        link: 'READ MORE',
        description: 'Lorem sum has been the industry standard dummy of the text ever since the scrambled it to make.',
        author: 'Loura Sweety',
        create_at: '25 Sep 2024',
        blogSingleImg: blogSingleImg1,
        comment: '35',
        day: '28',
        month: 'MAR',
        blClass: 'format-standard-image',
        animation: '1200',
    },
    {
        id: '2',
        title: 'Lorem Ipsum is simply dummy text of printing and typesetting.',
        title2: 'How Architects Design for Less Lonely Living',
        subtitle: 'ARCHITECTURE / BY: KARIANAS',
        slug: 'How-To-Teach-Kids-Ramadan-Isn’t-About-Food2',
        screens: blogImg2,
        link: 'READ MORE',
        description: 'Lorem sum has been the industry standard dummy of the text ever since the scrambled it to make.',
        author: 'Loura Sweety',
        create_at: '25 Sep 2024',
        blogSingleImg: blogSingleImg2,
        comment: '35',
        day: '29',
        month: 'MAR',
        blClass: 'format-standard-image',
        animation: '1200',
    },
    {
        id: '3',
        title: 'Lorem Ipsum is simply dummy text of printing and typesetting.',
        title2: '5 Ways to Make Your Home Look Architectural',
        subtitle: 'ARCHITECTURE / BY: Jon',
        slug: 'How-To-Teach-Kids-Ramadan-Isn’t-About-Food3',
        screens: blogImg3,
        link: 'READ MORE',
        description: 'Lorem sum has been the industry standard dummy of the text ever since the scrambled it to make.',
        author: 'Loura Sweety',
        create_at: '25 Sep 2024',
        blogSingleImg: blogSingleImg3,
        comment: '35',
        day: '30',
        month: 'MAR',
        blClass: 'format-video',
        animation: '1200',
    },
    {
        id: '4',
        title: 'Lorem Ipsum is simply dummy text of printing and typesetting.',
        subtitle: 'ARCHITECTURE / BY: Jon',
        slug: 'How-To-Teach-Kids-Ramadan-Isn’t-About-Food4',
        screens: blogImg4,
        link: 'READ MORE',
        description: 'Lorem sum has been the industry standard dummy of the text ever since the scrambled it to make.',
        author: 'Loura Sweety',
        create_at: '25 Sep 2024',
        //blogSingleImg: blogSingleImg4,
        comment: '35',
        day: '25',
        month: 'MAR',
        blClass: 'format-standard-image',
        animation: '1200',
    },


];
export default blogs;



