import timg1 from '../images/team/1.jpg'
import timg2 from '../images/team/2.jpg'
import timg3 from '../images/team/3.jpg'
import timg4 from '../images/team/4.jpg'




import Stime1 from '../images/team/s1.jpg'
import Stime2 from '../images/team/s2.jpg'
import Stime3 from '../images/team/s3.jpg'
import Stime4 from '../images/team/s4.jpg'



const Teams = [
    {
        id: '1',
        title: 'Henry Bannet',
        subtitle: 'Creative Director',
        slug: 'Henry-Bannet',
        timg: timg1,
        Sime:Stime1,
    },
    {
        id: '2',
        title: 'Tanean Akrasen',
        subtitle: 'HEAD OF PLANNING',
        slug: 'Tanean-Akrasen',
        timg: timg2,
        Sime:Stime2,
    },
    {
        id: '3',
        title: 'Lily Margerat',
        subtitle: 'Art Director',
        slug: 'Lily-Margerat',
        timg: timg3,
        Sime:Stime3,
    },
    {
        id: '4',
        title: 'William Watson',
        subtitle: 'Creative Director',
        slug: 'William-Watson',
        timg: timg4,
        Sime:Stime4,
    },
    {
        id: '5',
        title: 'William Watson',
        subtitle: 'Creative Director',
        slug: 'William-Watson',
        timg: timg2,
        Sime:Stime2,
    }
]

export default Teams;



