import React from "react";
import { Navigation, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Link } from 'react-router-dom'
import hero3 from '../../images/slider/slide-3.jpg'
import hero4 from '../../images/slider/slide-5.jpg'


const Hero4 = () => {
    return (

        <section className="wpo-hero-slider" >
            <Swiper
                // install Swiper modules
                modules={[Navigation, A11y]}
                spaceBetween={0}
                slidesPerView={1}
                loop={true}
                speed={1800}
                parallax={true}
                navigation
            >
                <SwiperSlide>
                    <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${hero3})` }}>
                        <div className="container">
                            <div className="slide-content">
                                <div className="slide-title">
                                    <h2>Make your
                                        statement
                                        in style</h2>
                                </div>
                                <div className="slide-text">
                                    <p>Lorem ipsum dolor sit amet sed, adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna aliqua ut enim ad.</p>
                                </div>
                                <div className="clearfix"></div>
                                <div className="slide-btns">
                                    <Link to="/about" className="theme-btn">Explore more</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${hero4})` }}>
                        <div className="container">
                            <div className="slide-content">
                                <div className="slide-title">
                                    <h2> Simplicity wins over complexity </h2>
                                </div>
                                <div className="slide-text">
                                    <p>Lorem ipsum dolor sit amet sed, adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna aliqua ut enim ad.</p>
                                </div>
                                <div className="clearfix"></div>
                                <div className="slide-btns">
                                    <Link to="/about" className="theme-btn">Explore more</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

                ...
            </Swiper>
        </section>
    )
}

export default Hero4;